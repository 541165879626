<template>
  <div v-if="!leaseDocumentsLoading">
    <hb-form
      label="Lease Agreement"
      :required="!readMode ? true : false"
      :editable="(editMode && isPermissionToEdit && !leaseDocumentsLoading) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active="active"
      @editStart="handleEditStart('Lease Agreement')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
      v-if="!facilityLevel || (facilityLevel && leaseTemplateChecklist['lease'].length && leaseTemplateChecklist['lease'][0].document_id)"
    >
      <template v-if="!facilityLevel" v-slot:tooltipBody>
        You must include a lease agreement to <br />
        proceed with the lease configuration <br />
        process. The lease document you should <br />
        use depends on the state where you <br />
        operate.
      </template> 
      <template v-slot:display>
        <div v-if="leaseDocumentsLoading" class="d-flex align-center">
          Loading Lease Agreement Documents..
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else class="mt-n2">
            <div v-for="(doc, leaseDocIndex) in leaseTemplateChecklist['lease']" :key="leaseDocIndex" class="mt-3">
              <v-container
                v-if="findDocument(doc?.document_id, 'lease')"
                no-gutters 
                class="pa-0 ma-0"
              >
                 <v-container class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box pa-0 ma-0">
                  <template>
                    <v-row no-gutters>
                      <v-col cols="12" no-gutters>
                        {{ doc.name }}                                 
                      </v-col>
                      <v-col
                        cols="12"
                        no-gutters
                        class="mt-1"
                      >
                        <span class="font-weight-medium">
                          {{ findDocument(doc?.document_id, 'lease').name }}
                        </span>
                      </v-col> 
                    </v-row> 
                  </template> 
                 </v-container>
                 <v-container v-if="getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) && leaseTemplate.multi_space_rental" no-gutters class="pa-0 ma-0">
                  <template>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        no-gutters
                        class="mt-2"
                      >
                        <p v-bind:style="{ color: '#637381' }">Multi Space Lease Enabled</p>
                        <div v-if="leaseTemplate.multi_space_rental">
                          <template>
                            <v-row no-gutters class="mt-2">
                              <v-col
                                v-for="(option, i) in leaseTemplate.multi_space_rental_types"
                                :key="option.slug + i"
                                no-gutters
                                cols="12"
                              >
                                <HbCheckbox
                                  v-model="option.active"
                                  :label="option.name"
                                  :disabled="true"
                                  @change="handleMulitSpaceOptionsChange($event, option)"
                                />
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                      </v-col> 
                    </v-row>
                  </template>
                 </v-container>
              </v-container> 
              <div v-else> 
                <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
              </div> 
          </div>
        </div>
      </template>
      <template v-slot:edit>
        <v-container no-gutters class="pa-3 pl-0 mt-1 tenant-rental-document-library-v-container-box">
            <template>
              <v-row no-gutters>
                <v-col cols="12" no-gutters class="pt-1 tenant-rental-document-library-edit-margin-top-fix-1px">
                  <hb-autocomplete
                    v-if="leaseTemplateChecklist['lease']?.length && leaseTemplateChecklist['lease'][0].name"
                    v-model="leaseTemplateChecklist['lease'][0].document_id"
                    v-validate="`${ getLeaseDocumentsByKey('lease') ? 'required' : '' }`"
                    :items="getLeaseDocumentsByKey('lease')"
                    item-text="name"
                    item-value="id"
                    id="lease_document"
                    name="lease_document"                  
                    placeholder="Select Document"
                    data-vv-as="Lease Agreement Document"
                    data-vv-scope="form"
                    :error="errors.has('form.lease_document')"
                  >
                    <template v-slot:append-item>
                      <div id="lease" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          v-if="leaseTemplatesLoading"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </hb-autocomplete>
                </v-col>
                <v-col v-if="getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL)" cols="12" no-gutters class="pt-4"> 
                  <hb-switch right v-model="leaseTemplate.multi_space_rental" @change="handleMulitRentalChange($event)">
                      <template v-slot:label>
                        Enable Multi Space Lease
                      </template>
                      <template v-slot:tooltipBody>
                        A Multi-space Lease covers all spaces <br/>
                        rented by a tenant under a single rental<br/>
                        agreement. Any additional spaces of the <br/>
                        same type that are rented by the tenant <br/>
                        will not require an additional<br/>
                        signed agreement.
                      </template>
                  </hb-switch>
                  <div v-if="leaseTemplate.multi_space_rental">
                    <template>
                      <v-row no-gutters class="mt-2">
                        <v-col
                          v-for="(option, i) in leaseTemplate.multi_space_rental_types"
                          :key="option.slug + i"
                          no-gutters
                          cols="12"
                        >
                          <HbCheckbox
                            v-model="option.active"
                            :label="option.name"
                            :disabled="(option.slug === LEASE_CONFIGURATION.NEW_MOVE_IN_SLUG || option.slug === LEASE_CONFIGURATION.TRANSFER_ADD_SPACE_SLUG) ? true : false"
                            @change="handleMulitSpaceOptionsChange($event, option)"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </template>
        </v-container>

      </template>
    </hb-form>

    <hb-form
      label="AutoPay Enrollment"
      :required="(leaseTemplate.auto_pay && !readMode) ? true : false"
      :editable="(editMode && isPermissionToEdit && !leaseDocumentsLoading) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active="active"
      @editStart="handleEditStart('AutoPay Enrollment')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
      v-if="!facilityLevel || (facilityLevel && leaseTemplateChecklist['autopay'].length && leaseTemplateChecklist['autopay'][0].document_id)"
    >
      <template v-if="!facilityLevel" v-slot:tooltipBody>
        Have your tenants sign an AutoPay <br />
        enrollment document to authorize you to <br />
        run their payment method on an <br />
        automated schedule.
      </template>
      <template v-slot:display>
        <div v-if="leaseDocumentsLoading" class="d-flex align-center">
          Loading AutoPay Enrollment Documents..
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else class="mt-n2">
            <div v-if="leaseTemplateChecklist['autopay'].length">
              <div v-for="(doc, autoDocIndex) in leaseTemplateChecklist['autopay']" :key="autoDocIndex" class="mt-3">
              <v-container
                v-if="findDocument(doc?.document_id, 'autopay').name"
                no-gutters
                class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box"
              >
                  <template>
                    <v-row no-gutters>
                      <v-col cols="12" no-gutters>
                        {{ getDocumentNameByTag(doc) === 'Autopay Enrollment Document' ? 'AutoPay Enrollment Document' : getDocumentNameByTag(doc) }}
                      </v-col>
                      <v-col
                        cols="12"
                        no-gutters
                        class="mt-1"
                      >
                        <span class="font-weight-medium">
                          {{ findDocument(doc?.document_id, 'autopay').name || 'Add a Document' }}
                        </span>
                      </v-col>
                    </v-row>
                  </template> 
                </v-container>
                <div v-else> 
                  <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
                </div>
              </div>
            </div>
            <div v-else> 
                  <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
            </div>
            <div class="mt-3">
                <div>AutoPay will run {{ (leaseTemplate.auto_pay_after_billing_date == 1) ? leaseTemplate.auto_pay_after_billing_date + '-Delinquent' : leaseTemplate.auto_pay_after_billing_date }} days after the billing date.</div>
                <div class="mt-3">AutoPay will run a maximum of {{ leaseTemplate.auto_pay_max_times ? leaseTemplate.auto_pay_max_times : 0 }} times.</div>
            </div>
        </div>
      </template>
      <template v-slot:edit>
        <v-container no-gutters class="pa-3 pl-0 tenant-rental-document-library-v-container-box">
          <div class="tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="leaseTemplateChecklist['autopay']?.length && leaseTemplateChecklist['autopay'][0].name"
              v-model="leaseTemplateChecklist['autopay'][0].document_id"
              v-validate="leaseTemplate.auto_pay ? 'required' : ''"
              :items="getLeaseDocumentsByKey('autopay')"
              item-text="name"
              item-value="id"
              id="auto_pay_document"
              name="auto_pay_document"      
              placeholder="Select Document"
              data-vv-as="AutoPay Document"
              data-vv-scope="form"
              :error="errors.has('form.auto_pay_document')"
            >
              <template v-slot:append-item>
                <div id="autopay" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>
        <v-container no-gutters class="pl-0">
          <v-row no-gutters>
            <div class="hb-inline-sentence-text">
              AutoPay will run
            </div>

            <hb-select
              condensed
              box
              small
              v-model="leaseTemplate.auto_pay_after_billing_date"
              v-validate="'required'"
              :items="invoiceDays"
              id="auto_pay_after_billing_date"
              name="auto_pay_after_billing_date"
              data-vv-scope="form_2"
              data-vv-as="AutoPay Day"
              :error="errors.has('form_2.auto_pay_after_billing_date')"
            >
              <template v-slot:selection="data">
                <div v-if="data.item == 1" class="hb-overflow-handler" style="padding-top:3px;">{{data.item}}-Delinquent</div>
                <div v-else style="padding-top:3px;">{{data.item}}</div>
              </template>
              <template v-slot:item="data">
                <div v-if="data.item == 1" class="hb-overflow-handler">{{data.item}}-Delinquent</div>
                <div v-else>{{data.item}}</div>
              </template>
            </hb-select>

            <div class="hb-inline-sentence-text">
              days after the billing date.
            </div>
          </v-row>
          <v-row no-gutters class="mb-n3">
            <div class="hb-inline-sentence-text">
              AutoPay will run a maximum of
            </div>

            <HbSelect
              condensed
              box
              small
              v-model="leaseTemplate.auto_pay_max_times"
              v-validate="'required'"
              :items="invoiceDays"
              id="auto_pay_max_times"
              name="auto_pay_max_times"
              data-vv-scope="form_2"
              data-vv-as="AutoPay Run Max Times"
              :error="errors.has('form_2.auto_pay_max_times')"
            />

            <div class="hb-inline-sentence-text">
              times.
            </div>
          </v-row>
        </v-container>
      </template>
    </hb-form>
    
    <hb-form
      label="Coverage"
      :editable="(editMode && isPermissionToEdit && !leaseDocumentsLoading) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active="active"
      @editStart="handleEditStart('Coverage')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
       v-if="!facilityLevel || (facilityLevel && ((leaseTemplateChecklist['enroll-coverage'].length && leaseTemplateChecklist['enroll-coverage'][0].document_id) || (leaseTemplateChecklist['deny-coverage'].length && leaseTemplateChecklist['deny-coverage'][0].document_id)))"
    >
      <template v-if="!facilityLevel" v-slot:tooltipBody>
        Add an insurance/protection enrollment <br />
        document to require tenants signature <br /> 
        when enrolling in coverage and a deny <br/>
        document to require signature <br/>
        when declining coverage.
      </template>
      <template v-slot:display>
        <div v-if="leaseDocumentsLoading" class="d-flex align-center">
          Loading Coverage Documents..
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else class="mt-n2"> 
              <!-- Enroll Coverage Documents -->
              <div v-if="leaseTemplateChecklist['enroll-coverage']?.length && findDocument(leaseTemplateChecklist['enroll-coverage'][0].document_id, 'enroll-coverage')?.name">
                <div v-for="(doc, enrollIndex) in leaseTemplateChecklist['enroll-coverage']" :key="enrollIndex + 'enroll-coverage'" class="mt-3">
                  <v-container no-gutters class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box">
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12" no-gutters>
                          {{ getDocumentNameByTag(doc) === 'Autopay Enrollment Document' ? 'AutoPay Enrollment Document' : getDocumentNameByTag(doc) }}
                        </v-col>
                        <v-col cols="12" no-gutters class="mt-1">
                          <span class="font-weight-medium">
                            {{ findDocument(doc?.document_id, 'enroll-coverage')?.name || 'Add a Document' }}
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-container>
                </div>
              </div>

              <!-- Deny Coverage Documents -->
              <div v-if="leaseTemplateChecklist['deny-coverage']?.length && findDocument(leaseTemplateChecklist['deny-coverage'][0].document_id, 'deny-coverage')">
                <div v-for="(doc, denyIndex) in leaseTemplateChecklist['deny-coverage']" :key="denyIndex + 'deny-coverage'" class="mt-3">
                  <v-container no-gutters class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box">
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12" no-gutters>
                          {{ getDocumentNameByTag(doc) === 'Autopay Enrollment Document' ? 'AutoPay Enrollment Document' : getDocumentNameByTag(doc) }}
                        </v-col>
                        <v-col cols="12" no-gutters class="mt-1">
                          <span class="font-weight-medium">
                            {{ findDocument(doc?.document_id, 'deny-coverage')?.name || 'Add a Document' }}
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-container>
                </div>
              </div>
              <div v-if="!(leaseTemplateChecklist['enroll-coverage']?.length && findDocument(leaseTemplateChecklist['enroll-coverage'][0].document_id, 'enroll-coverage')) && !(leaseTemplateChecklist['deny-coverage']?.length && findDocument(leaseTemplateChecklist['deny-coverage'][0].document_id, 'deny-coverage'))" class="mt-3"> 
                <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
              </div>  
        </div>
      </template>
      <template v-slot:edit>
        <v-container no-gutters class="pa-3 pl-0 tenant-rental-document-library-v-container-box">
            <span class="hb-text-night">
              Insurance / Protection Enrollment
              <hb-tooltip class="d-inline-flex">
                <template slot="body">
                  Use an insurance/protection enrollment <br />
                  document to inform tenants that you <br />
                  require coverage to rent a space at your <br />
                  property.
                </template>
              </hb-tooltip>
            </span> 
          <div class="tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="leaseTemplateChecklist['enroll-coverage']?.length && leaseTemplateChecklist['enroll-coverage'][0].name"
              v-model="leaseTemplateChecklist['enroll-coverage'][0].document_id"
              :items="getLeaseDocumentsByKey('enroll-coverage')"
              item-text="name"
              item-value="id"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="insurance_protection_document"
              data-vv-as="Insurance / Protection Document"
              :error="errors.has('form.insurance_protection_document')"
            >
              <template v-slot:append-item>
                <div id="enroll-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>

        <v-container no-gutters class="pa-3 pl-0 tenant-rental-document-library-v-container-box">
          <span class="hb-text-night">
              Deny Insurance / Protection Form
              <hb-tooltip class="d-inline-flex">
                <template slot="body">
                  Tenants can deny the <br />
                  insurance/protection you provide at your <br />
                  property using a deny insurance <br />
                  document.
                </template>
              </hb-tooltip>
          </span> 
          <div class="tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="leaseTemplateChecklist['deny-coverage']?.length && leaseTemplateChecklist['deny-coverage'][0].name"
              v-model="leaseTemplateChecklist['deny-coverage'][0].document_id"
              :items="getLeaseDocumentsByKey('deny-coverage')"
              item-text="name"
              item-value="id"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="deny_insurance_protection_document"
              data-vv-as="Deny Insurance / Protection Document"
              :error="errors.has('form.deny_insurance_protection_document')"
            >
              <template v-slot:append-item>
                <div id="deny-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>
      </template>
    </hb-form>

    <hb-form
      label="Vehicle Addendum"
      :editable="(editMode && isPermissionToEdit && !leaseDocumentsLoading) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :empty="!documentFound && !readMode"
      :active="active"
      @editStart="handleEditStart('Vehicle Addendum')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
      v-if="!facilityLevel || (facilityLevel && leaseTemplateChecklist['vehicle'].length && leaseTemplateChecklist['vehicle'][0].document_id)"
    >
      <template v-if="!facilityLevel" v-slot:tooltipBody>
        A vehicle addendum is used when a <br />
        tenant informs you that they plan to store <br />
        a vehicle at your property.
      </template>
      <template v-slot:display>
        <div v-if="leaseDocumentsLoading" class="d-flex align-center">
          Loading Vehicle Addendum Documents..
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else class="mt-n2">
            <div v-if="leaseTemplateChecklist['vehicle'].length">
              <div v-for="(doc, vehicleDocIndex) in leaseTemplateChecklist['vehicle']" :key="vehicleDocIndex" class="mt-3">
                <v-container
                  v-if="findDocument(doc?.document_id, 'vehicle')"
                  no-gutters
                  class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box"
                >
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12" no-gutters>
                          {{ getDocumentNameByTag(doc) === 'Autopay Enrollment Document' ? 'AutoPay Enrollment Document' : getDocumentNameByTag(doc) }}
                        </v-col>
                        <v-col
                          cols="12"
                          no-gutters
                          class="mt-1"
                        >
                          <span class="font-weight-medium">
                            {{ findDocument(doc?.document_id, 'vehicle') || 'Add a Document' }}
                          </span>
                        </v-col>
                      </v-row>
                    </template> 
                </v-container>
                <div v-else> 
                  <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
                </div>
              </div> 
            </div> 
            <div v-else> 
                <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
            </div>
        </div>
      </template>
      <template v-slot:edit>
        <v-container no-gutters class="pa-3 pl-0 tenant-rental-document-library-v-container-box">
          <div class="tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="leaseTemplateChecklist['vehicle']?.length && leaseTemplateChecklist['vehicle'][0].name"
              v-model="leaseTemplateChecklist['vehicle'][0].document_id"
              :items="getLeaseDocumentsByKey('vehicle')"
              item-text="name"
              item-value="id"
              :clearable="false"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="vehicle_addendum_document"
              data-vv-as="Vehicle Addendum Document"
              :error="errors.has('form.vehicle_addendum_document')"
            >
              <template v-slot:append-item>
                <div id="vehicle" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>
      </template>
    </hb-form>

    <hb-form
      label="Military Waiver"
      :editable="(editMode && isPermissionToEdit && !leaseDocumentsLoading) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active="active"
      @editStart="handleEditStart('Military Waiver')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
      v-if="!facilityLevel || (facilityLevel && leaseTemplateChecklist['military'].length && leaseTemplateChecklist['military'][0].document_id)"
    >
      <template v-if="!facilityLevel" v-slot:tooltipBody>
        A military waiver is used when a tenant or <br />
        a spouse is a member of the U.S. military <br />
        and they are signing an SCRA.
      </template>
      <template v-slot:display>
        <div v-if="leaseDocumentsLoading" class="d-flex align-center">
          Loading Military Waiver Documents..
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else class="mt-n2">
            <div v-if="leaseTemplateChecklist['military'].length">
                <div v-for="(doc, militaryDocIndex) in leaseTemplateChecklist['military']" :key="militaryDocIndex" class="mt-3">
                <v-container
                  v-if="findDocument(doc?.document_id, 'military')"
                  no-gutters
                  class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box"
                >
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12" no-gutters>
                          {{ getDocumentNameByTag(doc) === 'Autopay Enrollment Document' ? 'AutoPay Enrollment Document' : getDocumentNameByTag(doc) }}
                        </v-col>
                        <v-col
                          cols="12"
                          no-gutters
                          class="mt-1"
                        >
                          <span class="font-weight-medium">
                            {{ findDocument(doc?.document_id, 'military') || 'Add a Document' }}
                          </span>
                        </v-col>
                      </v-row>
                    </template> 
                </v-container>
                <div v-else> 
                  <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
                </div>
              </div>
            </div>
            <div v-else>
                <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
            </div> 
        </div>
      </template>
      <template v-slot:edit>
        <v-container no-gutters class="pa-3 pl-0 tenant-rental-document-library-v-container-box">
          <div class="tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="leaseTemplateChecklist['military']?.length && leaseTemplateChecklist['military'][0].name"
              v-model="leaseTemplateChecklist['military'][0].document_id"
              :items="getLeaseDocumentsByKey('military')"
              item-text="name"
              item-value="id"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="military_document"
              data-vv-as="Military Document"
              :error="errors.has('form.military_document')"
            >
              <template v-slot:append-item>
                <div id="military" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>
      </template>
    </hb-form>

    <hb-form
      label="Other"
      :editable="(editMode && isPermissionToEdit && !leaseDocumentsLoading) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active="active"
      @editStart="handleEditStart('Other')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
      v-if="!facilityLevel || (facilityLevel && leaseTemplateChecklist['other'].length && leaseTemplateChecklist['other'][0].document_id)"
    >
      <template v-slot:display>
        <div v-if="leaseDocumentsLoading" class="d-flex align-center">
          Loading Other Documents....
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else class="mt-n2">
          <div v-if="leaseTemplateChecklist['other']?.length">
            <div v-for="(doc, otherDocIndex) in leaseTemplateChecklist['other']" :key="otherDocIndex" class="mt-3">
              <v-container
                v-if="findDocument(doc?.document_id, 'other')"
                no-gutters
                class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box"
              >
                  <template>
                    <v-row no-gutters>
                      <v-col cols="12" no-gutters>
                        {{ getDocumentNameByTag(doc) === 'Autopay Enrollment Document' ? 'AutoPay Enrollment Document' : getDocumentNameByTag(doc) }}
                      </v-col>
                      <v-col
                        cols="12"
                        no-gutters
                        class="mt-1"
                      >
                        <span class="font-weight-medium">
                          {{ findDocument(doc?.document_id, 'other') || 'Add a Document' }}
                        </span>
                      </v-col>
                    </v-row> 
                  </template> 
              </v-container>
              <div v-else>
                <div class="pt-1 pb-1 hb-text-light">
                  You may also add additional documents to include them to the list of materials your tenants must sign at move-in.
                </div>
                <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
              </div>
            </div>
          </div>
           <div v-else>
                <div v-if="!facilityLevel" class="pt-1 pb-1 hb-text-light">
                  You may also add additional documents to include them to the list of materials your tenants must sign at move-in.
                </div>
                <a v-if="!facilityLevel" class="hb-link">Click to Add</a>
            </div> 
        </div>
      </template>
      <template v-slot:edit>
        <div class="pt-2 pb-1 hb-text-light">
          You may also add additional documents to include them to the list of materials your tenants must sign at move-in.
        </div>

        <v-container
          v-for="(doc, docIndex) in noTagDocuments"
          :key="docIndex"
          no-gutters
          class="hb-aviary-bordered-box mt-3 pa-3 pt-5 tenant-rental-document-library-v-container-box"
        >
            <template>
              <v-row no-gutters>
                <v-col cols="10" no-gutters>
                  <hb-text-field
                    v-model="doc.name"
                    v-validate="'required|max:45'"
                    placeholder="Enter Custom Name"
                    data-vv-scope="form"
                    :data-vv-name="'custom_name_' + docIndex"
                    :data-vv-as="'Custom Name ' + (docIndex + 1)"
                    :error="errors.has('form.custom_name_' + docIndex)"
                    style="position:relative;top:-6px;"
                  >
                  </hb-text-field>
                </v-col>
                <v-col cols="2" no-gutters class="pl-7">
                  <hb-tooltip>
                    <template v-slot:item>
                      <hb-icon pointer @click="removeCustomDocument(docIndex)">mdi-close</hb-icon>
                    </template>
                    <template v-slot:body>
                      Remove
                    </template>
                  </hb-tooltip>
                </v-col>
                <v-col cols="12" no-gutters class="pt-1 tenant-rental-document-library-edit-margin-top-fix-1px">
                  <hb-autocomplete
                    v-model="doc.document_id"
                    v-validate="'required'"
                    :items="getLeaseDocumentsByKey('other')"
                    item-text="name"
                    item-value="id"
                    :filter="customFilter"
                    placeholder="Select Document"
                    data-vv-scope="form"
                    :data-vv-name="'custom_document_' + docIndex"
                    :data-vv-as="'Custom Document ' + (docIndex + 1)"
                    :error="errors.has('form.custom_document_' + docIndex)"
                  >
                    <template v-slot:append-item>
                      <div id="other" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          v-if="leaseTemplatesLoading"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </hb-autocomplete>
                </v-col>
              </v-row>
            </template>
        </v-container>

        <div class="pt-3 pb-1" v-if="showAddDocumentOption">
          <hb-link @click="addCustomDocument">+ Add Document</hb-link>
        </div>
      </template>
    </hb-form>
  </div>
  <div v-else> 
      <hb-form
        label="Lease Agreement"
        full
      >
      <template v-slot:display>
        <div style="margin: auto;">Loading Documents.. <v-progress-circular indeterminate color="primary"></v-progress-circular></div>
      </template>
      </hb-form> 
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js"; 
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';
import LEASE_CONFIGURATION from "@/constants/lease_configuration.js"; 
import SETTINGS from "@/constants/settings.js"; 
import { settingsMixin } from "@/mixins/settingsMixin.js";

export default {
  name: "TentantRentalDocumentLibrary",
  data() {
    return {
      showAddDocumentOption: true,
      // keys: lease,autopay,enroll-coverage,deny-coverage,vehicle,military,others
      leaseDocuments: [],
      leaseDocumentsLoading: true,
      leaseTemplatesLoading: false,
      allowScrollRequest: {
        'lease' :    true,
        'autopay' :  true,
        'vehicle': true,
        'enroll-coverage': true,
        'deny-coverage': true,
        'military': true,
        'other': true
      },
      focused: false,
      checklistItemsName: {
        lease : "Lease Agreement",
        'deny-coverage': "Deny Insurance/Protection Form"
      },
      clonedLeaseTemplate: {},
      leaseTemplateChecklist: {},
      multi_space_rental_types:[]
    };
  },
  // components: { HoverableEditField },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: "",
    },
    activelyEditing: {
      type: String,
      default: "",
    },
    leaseTemplate: {
      type: Object,
      required: true,
    },
    facilityLevel: {
      type: Boolean,
      default: false
    },
    activeTab: {

    }
  },
  mixins: [ notificationMixin, settingsMixin],
  async created() {
    this.LEASE_CONFIGURATION = LEASE_CONFIGURATION;
    this.SETTINGS = SETTINGS;
    await this.getDefaultMultiSpaceRentalTypes();
    await this.setInitialTemplateData();
    await this.fetchDocuments();
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    invoiceDays() {
      let days = Array(31)
        .fill(0)
        .map((n, i) => i );
      return days;
    },
    noTagDocuments() {
      const noTagDocuments = this.leaseTemplateChecklist["other"];
      return noTagDocuments;
    },
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    },
    documentFound() {
      let found = false;
      Object.keys(this.leaseTemplate.checklist).forEach(checklistItem => {
        this.leaseTemplate.checklist[checklistItem].map(doc => {
          if(doc.document_id) {
            found = true;
          }
        });
      });
      return found;
    }
  },
  methods: {
    async setInitialTemplateData(){

      this.leaseTemplateChecklist = this.leaseTemplate?.checklist ? JSON.parse(JSON.stringify(this.leaseTemplate.checklist)) : {};
      
      const checklistItems = LEASE_CONFIGURATION.CHECKLIST;
      checklistItems.forEach(item => {
        if (!this.leaseTemplateChecklist[item.key].length) {
          this.leaseTemplateChecklist[item.key].push({
            document_type_id: "",
            document_id: "",
            name: item.name,
            description: "",
            require_all: "",
            tag: item.tag,
            id: null
          });
        }
      });
     
      this.leaseTemplate.multi_space_rental_types ||= this.multi_space_rental_types;
    },
    async getDefaultMultiSpaceRentalTypes(){
      try {
          let res = await api.get(this, api.TEMPLATES + 'multi-space-rental-types');
          this.multi_space_rental_types = res.multi_space_rental_types;
          this.multi_space_rental_types = this.multi_space_rental_types.map(item => {
            return {
              ...item,
              active: item.default_status === 1 ? 1 : 0,
              object_type: LEASE_CONFIGURATION.LEASE_TEMPLATE
            };
          }); 
      } catch(err) {
          this.multi_space_rental_types = [];
      } 
    },
    handleMulitSpaceOptionsChange(event, option) { 
      option.active = event === true ? 1 : 0; 
    },
    handleMulitRentalChange(event){
      console.log(`Multi Rental Checkbox: ${event}`);
    },
    getDocumentNameByTag(doc) {
      return this.checklistItemsName[doc.document_tag] ? this.checklistItemsName[doc.document_tag] : doc.name;
    },
    async getMoreDocumentTemplates(entries, observer, isIntersecting){
      if (isIntersecting && this.allowScrollRequest[entries[0].target.id] && !this.leaseTemplatesLoading) {
        this.leaseTemplatesLoading = true;
        this.fetchMoreDocuments(entries[0].target.id);
        }
    },
    isDocumentSelected(tag) {
      return this.leaseTemplate.checklist[tag].length && (this.leaseTemplate.checklist[tag][0].name && !this.leaseTemplate.checklist[tag][0].tag)
    },
    updateRequiredDocuments(isChecked, checkedDocumentTag, checkedDocumentName) {
      if (isChecked) {
        this.leaseTemplate.checklist[checkedDocumentTag].push({
          document_type_id: "",
          document_id: "",
          name: checkedDocumentName,
          description: "",
          require_all: "",
          tag: checkedDocumentTag,
        });
      } else {
        this.leaseTemplate.checklist[checkedDocumentTag] = [];
        // this.leaseTemplate.checklist = this.leaseTemplate.checklist.filter(checklist => checklist.tag !== value2);
      }
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase().split(" ");
      const searchText = queryText.toLowerCase();
      return textOne[0].indexOf(searchText) > -1;
    },
    addCustomDocument() {
      this.leaseTemplateChecklist["other"].push({
        document_type_id: "",
        document_id: "",
        name: "",
        description: "",
        require_all: "",
        tag: "other",
      });
    },
    removeCustomDocument(index) {
      this.leaseTemplateChecklist["other"].splice(index, 1);
    },
    getLeaseDocumentsByKey(key) {
      const leaseDocuments = this.leaseDocuments && this.leaseDocuments.find((doc) => doc.type === key);
      return leaseDocuments;
    },
    findDocument(documentId) {
      return this.leaseDocuments.find(d => d.id == documentId)
    },
    async fetchDocuments() {
      try {
        this.leaseDocumentsLoading = true;
        let res = await api.get(this, api.DOCUMENT + "?template=true");
        this.leaseDocuments = res.documents;
      } catch (err) {
        // set notification!
        this.showMessageNotification({type: 'error', description: err});
        EventBus.$emit('documentsLoaded',false);
      } finally { 
        EventBus.$emit('documentsLoaded',true);
        this.leaseDocumentsLoading = false;
      }
    },
    async fetchMoreDocuments(tag){

        this.leaseTemplatesLoading = true;

        let tagDocuments = this.getLeaseDocumentsByKey(tag);
        let limit = 20;

        if(!tagDocuments || tagDocuments.length < limit){
          this.allowScrollRequest[tag] = false;
          this.leaseTemplatesLoading = false;
          return;
        }

        let offset = tagDocuments.length == 0 ? 1 : Math.ceil(tagDocuments.length / limit) + 1 ;
        let params = `?limit=${limit}&offset=${offset}&type[]=${tag}&template=true`;
    
        api.get(this, api.DOCUMENT + params).then(r => {
            if(r.documents.length == 0) {
              this.allowScrollRequest[tag] = false;
            }else{
              this.allowScrollRequest[tag] =  true;
            }
          
            this.leaseDocuments.forEach(tagDoc => {
              if(tagDoc.key === tag){
                this.tempDocs = [...tagDoc.value, ...r.documents[0].value];
                tagDoc.value = this.tempDocs;
              }
            })
            this.leaseTemplatesLoading = false;
          }).catch(err => {
              this.showMessageNotification({type: 'error', description: err});
              this.leaseTemplatesLoading = false;
          });
      },
    async validateForm() {
      /*if(!this.leaseTemplate.checklist || !this.leaseTemplate.checklist.lease || !this.leaseTemplate.checklist.lease.length || 
      !this.leaseTemplate.checklist.lease[0].document_id) {
        return false
      }*/

      let status = await this.$validator.validateAll("form");
      return status;
    },
    handleEmittedCancel(){
      this.leaseTemplateChecklist = JSON.parse(JSON.stringify(this.clonedLeaseTemplate.checklist));
      EventBus.$emit('lease-configuration-revert-to-cached-template', this.clonedLeaseTemplate);
      setTimeout(() => {
        if(this.active === 'Lease Agreement'){
          this.$emit('setActive', '');
        }
      }, 500);
    },
    handleEmittedSave(){ 
      this.leaseTemplate.checklist = JSON.parse(JSON.stringify(this.leaseTemplateChecklist)); 
      this.$emit('save');
    },
    handleEditStart(label){
      setTimeout(() => {
        this.leaseTemplate.checklist = JSON.parse(JSON.stringify(this.leaseTemplateChecklist));
        this.clonedLeaseTemplate = JSON.parse(JSON.stringify(this.leaseTemplate));
      }, 200);
      this.$emit('setActive', label);
    }
  },
  watch: {
    "activeTab"(){
      if(this.activeTab !== 1 && this.active === 'Lease Agreement'){
        EventBus.$emit('lease-configuration-revert-to-cached-template', this.clonedLeaseTemplate);
      }
    },
    leaseTemplateChecklist: {
      handler(newcheckList, oldcheckList) {
        if (newcheckList) {
          this.leaseTemplate.checklist = JSON.parse(JSON.stringify(newcheckList));
        }
      },
      deep: true, // Enable deep watching
    },
  },
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}

.tenant-rental-document-library-v-container-box {
  max-width: 370px;
  background: #fff;
}

.tenant-rental-document-library-edit-margin-top-fix-1px {
  margin-top: 1px;
}

.tenant-rental-document-library-edit-margin-top-fix-3px {
  margin-top: 3px;
}
</style>