<template>
  <div>
    <tenant-rental-document-library 
      v-if="!isOPS" 
      ref="TenantRentalDocumentLibrary" 
      :editMode="editMode"
      :readMode="readMode" 
      :activelyEditing="activelyEditing" 
      :leaseTemplate.sync="template"
      :facilityLevel="facilityLevel" 
      :active.sync="active" 
      :active-tab="activeTab" 
      @setActive="handleSetActive"
      @save="handleEmittedSave" 
      @cancel="handleEmittedCancel" 
    />
  </div>
</template>

<script type="text/babel"> 
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';
import TenantRentalDocumentLibrary from "./TenantRentalDocumentLibrary.vue";

export default {
  name: "DocumentsConfigurations",
  components: {
    TenantRentalDocumentLibrary
  },
  props: ["template", "activelyEditing", "editMode", "readMode", "facilityLevel", 'isOPS', "contentPosition", "activeTab"],
  data() {
    return {
      active: ''
    };
  },
  created(){
    EventBus.$on('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  destroyed() {
    EventBus.$off('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
      // return false;
    }
  },
  methods: {
    async validateForm() {
      const status = await this.$validator.validateAll("form_2");  
      const documentLibraryValidationStatus = this.isOPS ? true : await this.$refs.TenantRentalDocumentLibrary.validateForm();
      return status && documentLibraryValidationStatus;
    },
    handleEmittedCancel(){
      this.$emit('cancelEdit');
    },
    handleEmittedSave(){
      this.$emit('saveData');
    },
    handleSetActive(label){
      this.active = label;
    },
    clearActive(){
      this.active = '';
    }
  },
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
</style>
